<template>
  <div class="wrapper">
    <div class="content-wrapper">
      <section class="content-header">
        <div class="container-fluid">
          <!-- Default box -->

          <div class="row" :class="$store.getters.getDarkMode ? 'dark-mode' : 'bg-white'">
            <div class="col-12 col-sm-6 col-md-6">
              <div class="card bg-frontera-top-left text-white h-100">
                <div class="card-header text-muted border-bottom-0 text-white">
                  Correos
                </div>
                <div class="card-body pt-0 text-white">
                  <div class="row">
                    <div class="col-8">
                      <p class="text-muted text-sm text-white">
                        Para recibir ayuda puede comunicarse a los siguientes
                        correos
                      </p>
                      <ul class="ml-4 mb-0 fa-ul text-muted">
                        <li>
                          <span class="fa-li text-white"
                            ><i class="fas fa-at"></i
                          ></span>
                          <a href="mailto:pegaso@fronteraenergy.ca"
                            >pegaso@fronteraenergy.ca</a
                          >
                        </li>
                        <li>
                          <span class="fa-li text-white">
                            <i class="fas fa-at"></i>
                          </span>
                          <a
                            href="mailto:central.frontera@soscontingencias.com.co"
                            >central.frontera@soscontingencias.com.co</a
                          >
                        </li>
                        <li>
                          <span class="fa-li text-white"
                            ><i class="fas fa-at"></i
                          ></span>
                          <a href="mailto:camilo.neiza@soscontingencias.com.co"
                            >camilo.neiza@soscontingencias.com.co</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="col-4 text-center">
                      <i class="fas fa-envelope" style="font-size: 70px"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6">
              <div class="card bg-frontera-top-left text-white h-100">
                <div class="card-header text-muted border-bottom-0 text-white">
                  Teams
                </div>
                <div class="card-body pt-0">
                  <div class="row">
                    <div class="col-8">
                      <p class="text-muted text-sm text-white">
                        <b>Teams: </b> Atención Mesa de ayuda SOS CONTINGENCIAS
                        Información para unirse a Teams Enlace a la videollamada:
                      </p>
                      <a
                        class="btn bg-purple"
                        href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_YjQ1NjVjYWMtMTRhZi00MDQ5LWJkNTQtNmZjNDI2NjM0NzJk%40thread.v2/0?context=%7b%22Tid%22%3a%222637d50c-c560-436c-8997-ebe63c9656fd%22%2c%22Oid%22%3a%221e250963-6d90-45b7-be49-21c840951f6c%22%7d"
                        target="_blank"
                        ><i class="fas fa-users"></i> | Unirme a Teams
                      </a>
                    </div>
                    <div class="col-4 text-center">
                      <i class="fas fa-users" style="font-size: 70px"></i>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6">
              <div class="card bg-frontera-top-left text-white h-100 mt-3">
                <div class="card-header text-muted border-bottom-0 text-white">
                  Chat
                </div>
                <div class="card-body pt-0">
                  <div class="row">
                    <div class="col-7">
                      <p class="text-muted text-sm text-white">
                        <b>Whatsapp: </b> Para conectarse con nuestro Whatsapp
                      </p>
                      <ul class="ml-4 mb-0 fa-ul text-muted text-white">
                        <li>
                          <span class="fa-li text-white">
                            <i class="fab fa-whatsapp"></i>
                          </span>
                          +57 318 6952818
                        </li>
                      </ul>
                      <br />
                      <a
                        class="btn btn-success"
                        href="https://wa.link/d0tvs4"
                        target="_blank"
                        ><i class="fab fa-whatsapp"></i> Chatear
                      </a>
                    </div>
                    <div class="col-5 text-center">
                      <i class="fab fa-whatsapp" style="font-size: 70px"></i>
                      <br />
                      <img
                        src="/img/wpp_mesa_ayuda_qr.png"
                        alt=""
                        width="120px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6">
              <div class="card bg-frontera-top-left text-white h-100 mt-3">
                <div class="card-header text-muted border-bottom-0 text-white">
                  Llamada
                </div>
                <div class="card-body pt-0 text-white">
                  <div class="row">
                    <div class="col-8">
                      <p class="text-muted text-sm text-white">
                        <b>Linea: </b> Recibimos llamadas 24/7 con nuestra mesa
                        de ayuda
                      </p>
                      <ul class="ml-4 mb-0 fa-ul text-muted text-white">
                        <li>
                          <span class="fa-li text-white"
                            ><i class="fas fa-lg fa-phone"></i
                          ></span>
                          +57 317 6431376
                        </li>
                      </ul>
                      <br />
                      <a
                        class="btn btn-info"
                        href="tel:+573176431376"
                        target="_blank"
                        ><i class="fas fa-phone-square-alt"></i> Llamar
                      </a>
                    </div>
                    <div class="col-4 text-center">
                      <i
                        class="fas fa-phone-square-alt"
                        style="font-size: 70px"
                      ></i>
                      <br />
                      <img
                        src="/img/contacto_mesa_ayuda_qr.jpeg"
                        alt=""
                        width="120px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- /.card -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {};
</script>
